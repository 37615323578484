@use "~scss/settings" as *;

.spb-contract-information {
  &__field-container {
    .form-field-label {
      margin-bottom: 12px;
    }
  }

  &__checkbox-with-label {
    @include flex(row-reverse);
    gap: 8px;
    width: fit-content;
    margin: 8px 0;
    margin-top: 24px;

    h5 {
      font-weight: 500;
    }
  }

  &__probationary-field {
    @include flex(column);
    gap: 16px;
  }
}
