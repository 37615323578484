@use "~scss/settings" as *;

.contract-duration-field {
  @include flex(column);

  gap: 8px;

  &__fields {
    @include flex(row, flex-start, center);

    gap: 12px;

    > * {
      flex: 1;
    }
  }

  &__error {
    @extend %input-descriptive;

    color: $color-error;
    margin-top: 8px;
    margin-left: 4px;
  }

  &__indefinite {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    min-height: 64px;

    border: 1px solid $color-grey-d6;
    opacity: 0.5;
    border-radius: 5px;
    background-color: $color-grey-fc;
    padding: 8px 15px;
    padding-bottom: 12px;

    label {
      color: $color-grey-af;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
